<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="account_id">Account:</label>
          <select class="form-control" name="account_id" v-model.number="account_id" required>
            <option v-for="acc in accounts" :key="acc" :value="acc.id">{{acc.account_name}}</option>
          </select>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="user_name">Name:</label>
          <input class="form-control" required="" name="name" type="text" v-model="name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="contact_person">Email:</label>
        <input class="form-control" required="" name="email" type="email" v-model="email">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="contact_phone">Phone:</label>
        <input class="form-control" name="phone" type="text" v-model="phone">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="contact_phone">Password:</label>
        <input class="form-control" type="text" id="password" name="password" v-model="password" minlength="8" pattern="^(?=.*\d)(?=.*[!@#$%^&*()+\-=[\]{};':\\|,.<>\/?])(?=.*[a-zA-Z]).{8,}$" required>
        <small style="color:red">Minimum of 8 characters, containing at least 1 number, 1 special character, and 1 letter.</small>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/users">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('users', ['onRequest']),
    },
    data(){
      return {
        name: '',
        email: '',
        phone: '',
        password: '',
        account_id: '',
        status: 1,
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {name, email, phone, password, account_id, status} = this
        if (name && email && password && account_id && status) {
            this.add({ name, email, phone, password, account_id, status })
        }
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('users', ['add']),
    },
    created() {
      this.$emit('onChildInit', 'Add New User')
      this.get_all()
    },
  }
</script>
