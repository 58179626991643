<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" v-model.number="work_order.account_id" :options="accountOptions" @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="vehicle_id">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="work_order.vehicle_id" :options="vehicleOptions" @change="onChangeVehicle($event)" @select="onChangeVehicle($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="km_vehicle">KM Vehicle:</label>
        <input type="number" class="form-control" name="km_vehicle" v-model.number="work_order.km_vehicle" step="any">
      </div>
      <div class="form-group col-sm-12">
        <label for="description">Description:</label>
        <textarea class="form-control" name="description" v-model="work_order.description" maxlength="1000"></textarea>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
          <label for="start_date">Start Date:</label>
          <input class="form-control" required="" name="start_date" type="date" v-model="work_order.start_date">
      </div>
      <TimeComponent :label="'Start Time'" :selected_time="work_order.start_time" v-on:selected-time="setStart"/>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="work_order_address">End Date:</label>
        <input class="form-control" required="" name="end_date" type="date" v-model="work_order.end_date">
      </div>
      <TimeComponent :label="'End Time'" :selected_time="work_order.end_time" v-on:selected-time="setEnd"/>      
      <div class="form-group col-sm-12 col-md-6">
        <label for="advisor">Advisor:</label>
        <input type="text" class="form-control" name="advisor" v-model="work_order.advisor">
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="mechanic">Mechanic:</label>
        <input type="text" class="form-control" name="mechanic" v-model="work_order.mechanic">
      </div>
      <div class="form-group col-sm-12 col-md-4 required">
        <label for="status">Type:</label>
        <select class="form-control" name="status" v-model.number="work_order.type">
          <option value="1" style="color:black;">Service</option>
          <option value="2" style="color:black;">Inspection</option>
          <option value="3" style="color:black;">Repair / Accident</option>
          <option value="4" style="color:black;">Others</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-4 required">
        <label for="service_by">Service By:</label>
        <select class="form-control" name="service_by" v-model.number="work_order.service_by">
          <option value="1" style="color:black;">3rd Party</option>
          <option value="2" style="color:black;">Own Workshop</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-4 required">
        <label for="status">Priority:</label>
        <select class="form-control" name="status" v-model.number="work_order.priority">
          <option value="1" style="color:black;">Low</option>
          <option value="2" style="color:black;">Medium</option>
          <option value="3" style="color:black;">High</option>
        </select>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="work_order.status">
          <option value="1" style="color:black;">Pending</option>
          <option value="2" style="color:black;">Started</option>
          <option value="3" style="color:black;">On Progress</option>
          <option v-if="isAbleToFinish" value="4" style="color:black;">Completed</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <label for="remarks">Remarks:</label>
        <textarea class="form-control" name="remarks" v-model="work_order.remarks" maxlength="500"></textarea>
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="maintenance_schedule_id">Maintenance Schedule:</label>
        <Select2 name="maintenance_schedule_id" v-model.number="work_order.maintenance_schedule_id" :options="maintenanceOptions" @change="onChangeSchedule($event)" @select="onChangeSchedule($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="estimation_cost">Estimation Cost:</label>
        <input type="text" class="form-control" name="estimation_cost" v-model="estimation_cost" disabled>
      </div>
      <InputImage label="Picture" v-on:selected-image="setImage"/>      
      <div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="form-group col-sm-12">
            <h4>Add Part</h4>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="part_id">Part:</label>
            <Select2 name="inventory_id" v-model.number="inventory_id" :options="inventoryOptions"/>
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="qty">Qty:</label>
            <input class="form-control" name="qty" type="text" v-model.number="qty" min="1">
          </div>
          <div class="form-group col-sm-12">
            <button v-if="this.isEditPart == false" class="btn btn-success" @click.prevent="clickAddPart($event)">Add</button>
            <button v-else class="btn btn-success" @click.prevent="clickAddPart($event)">Update</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Part Name</th>
                  <th>Part Code</th>
                  <th>UOM</th>
                  <th>Qty</th>
                  <th>Price / Qty</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in work_order.work_order_parts" :key="index">
                <td>{{item.inventory.part.part_name}}</td>
                <td>{{item.inventory.part.part_code}}</td>
                <td>{{item.inventory.part.uom}}</td>
                <td>{{formatNumber(item.qty)}}</td>
                <td>{{formatNumber(item.inventory.price_qty)}}</td>
                <td>                  
                  <button class="btn btn-sm btn-info" @click="clickEditPart($event, index)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-1" @click="clickRemovePart($event, index)">Remove</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="form-group col-sm-12">
            <h4>Add Detail Cost</h4>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="cost_name">Cost Name:</label>
            <input class="form-control" name="cost_name" type="text" v-model="cost_name">
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="cost_amount">Cost Amount:</label>
            <input class="form-control" name="cost_amount" type="text" v-model="cost_amount_txt">
          </div>
          <div class="form-group col-sm-12 col-md-12">
            <label for="note">Note:</label>
            <textarea class="form-control" name="note" v-model="note"></textarea>
          </div>
          <div class="form-group col-sm-12">
            <button v-if="this.isEdit == false" class="btn btn-success" @click.prevent="clickAdd($event)">Add</button>
            <button v-else class="btn btn-success" @click.prevent="clickAdd($event)">Update</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Note</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in work_order.cost_details" :key="index">
                <td>{{item.cost_name}}</td>
                <td>{{formatNumber(item.cost)}}</td>
                <td>{{item.note}}</td>
                <td>                  
                  <button class="btn btn-sm btn-info" @click="clickEdit($event, index)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-1" @click="clickRemove($event, index)">Remove</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/work_orders">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import TimeComponent from '../components/TimeComponent.vue'
  import InputImage from '../components/InputImage.vue'
  import Select2 from 'vue3-select2-component';
  import { getCapability } from '../_helpers'
  export default {
    computed: {
      ...mapState('accounts', ['accounts']),
      ...mapState('vehicles', ['vehicles']),
      ...mapState('work_orders', ['onRequest']),
      ...mapState('inventories', ['inventories']),
      ...mapState('maintenance_schedules', ['maintenance_schedules']),
      ...mapState('users', ['user']),
      isAbleToFinish(){
        return getCapability('finish_work_order', this.user)
      },
      cost_amount_txt:{
        get() {
          return this.cost_amount ? this.cost_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.modelValue
        },
        set(value) {
          this.cost_amount = Number(value.replaceAll(',',''))
        }
      }
    },
    data(){
      return {
        estimation_cost: null,
        work_order: {
          account_id: null,
          vehicle_id: null,
          start_date: moment().format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD"),
          start_time: '00:00',
          end_time: '23:59',
          description: '',
          remarks: '',
          advisor: '',
          km_vehicle: '',
          mechanic: '',
          priority: 1,
          service_by: 1,
          type: 1,
          status: 1,
          cost_details: [],
          work_order_parts: [],
          maintenace_schedule_id: null,
        },
        file_image: null,
        vehicleOptions: [],
        accountOptions: [],
        isEdit: false,
        editIndex: null,
        cost_name: '',
        cost_amount: null,
        note: '',
        inventoryOptions: [],
        inventory_id: null,
        qty: null,
        isEditPart: false,
        editIndexPart: null,
        maintenanceOptions: []
      }
    },
    components:{
      TimeComponent,
      InputImage,
      Select2
    },
    methods: {
      formatNumber(value){
        return value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : value
      },
      handleSubmit(){
        if(this.onRequest) return;
        const {work_order, file_image} = this
        const {account_id, vehicle_id, description, remarks, priority, type, status, start_date, end_date, start_time, end_time, service_by, cost_details, km_vehicle, advisor, mechanic, work_order_parts, maintenance_schedule_id} = work_order
        let start = moment(start_date + " " + start_time, "YYYY-MM-DD HH:mm")
        let end = moment(end_date + " " + end_time, "YYYY-MM-DD HH:mm")
        this.add({account_id, vehicle_id, description, remarks, priority, type, status, service_by, start, end, file_image, cost_details, km_vehicle, advisor, mechanic, work_order_parts, maintenance_schedule_id})
      },
      setStart(val){
        this.work_order.start_time = val
      },
      setEnd(val){
        this.work_order.end_time = val
      },
      onChangeVehicle(event){
        let vehis = this.vehicles.filter(item => item.id === parseInt(event.id))
        if(vehis.length > 0){
          this.work_order.km_vehicle = vehis[0].odometer
        }
        this.get_maintenance_schedule_by_vehicle({vehicle_id: parseInt(event.id)})
      },
      onChange(event){
        this.getVehicles({account_id: parseInt(event.id)})
        this.get_inventory_by_account({account_id: parseInt(event.id)})
        this.work_order.vehicle_id = null
      },
      onChangeSchedule(event){
        this.estimation_cost = null
        if(event.id === null){
          return;
        }
        let maintenance_schedule_id = parseInt(event.id)
        let maintenance = this.maintenance_schedules.filter(item => item.id === maintenance_schedule_id)
        if(maintenance.length > 0){
          this.estimation_cost = this.formatNumber(maintenance[0].cost_estimation)
        }
      },
      ...mapActions('work_orders', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_account'}),
      ...mapActions('inventories', ['get_inventory_by_account']),
      ...mapActions('maintenance_schedules', ['get_maintenance_schedule_by_vehicle']),
      setImage(image){
        this.file_image = image
      },
      clickAdd(){
        if(this.cost_name === '' || this.cost_amount == null || this.cost_amount < 0){
          return;
        }
        if(isNaN(this.cost_amount)){
          return
        }
        let obj = {
          cost_name: this.cost_name,
          cost: this.cost_amount,
          note: this.note,
        }
        if(this.isEdit){
          this.work_order.cost_details[this.editIndex] = obj
        } else {
          this.work_order.cost_details.push(obj)
        }
        this.resetData()
      },      
      clickEdit(event, index){
        event.preventDefault()
        const {cost, cost_name, note} = this.work_order.cost_details[index]
        this.cost_name = cost_name
        this.cost_amount = cost
        this.note = note
        this.isEdit = true
        this.editIndex = index
      },
      clickRemove(event, index){
        event.preventDefault()
        this.work_order.cost_details.splice(index, 1)
        this.resetData()
      },
      resetData(){
        this.isEdit= false
        this.editIndex= null
        this.cost_name= ''
        this.cost_amount= null
        this.note= ''
      },
      clickAddPart(){
        if(this.inventory_id === '' || this.inventory_id == null || this.qty < 0 || this.inventories.length < 1){
          return;
        }
        let inventory = this.inventories.filter(item => item.id == this.inventory_id)
        if(inventory.length < 1){
          return;
        }
        let obj = {
          inventory_id: this.inventory_id,
          qty: this.qty,
          inventory: inventory[0]
        }
        if(this.isEditPart){
          this.work_order.work_order_parts[this.editIndexPart] = obj
        } else {
          this.work_order.work_order_parts.push(obj)
        }
        this.resetDataPart()
      },      
      clickEditPart(event, index){
        event.preventDefault()
        const {inventory_id, qty} = this.work_order.work_order_parts[index]
        this.inventory_id = inventory_id
        this.qty = qty
        this.isEditPart = true
        this.editIndexPart = index
      },
      clickRemovePart(event, index){
        event.preventDefault()
        this.work_order.work_order_parts.splice(index, 1)
        this.resetDataPart()
      },
      resetDataPart(){
        this.isEditPart= false
        this.editIndexPart= null
        this.inventory_id= null
        this.qty= null
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Work Order')
      this.get_all()
    },
    watch: {
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      inventories(nextState, prevState){
        if(nextState !== prevState){
          this.inventoryOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.inventoryOptions.push({id:item.id, text:item.part.part_name + ' - ' + item.part.part_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      maintenance_schedules(nextState, prevState){
        if(nextState !== prevState){
          this.maintenanceOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.maintenanceOptions.push({id:item.id, text:item.maintenance_name})
            })
          }
          return
        }
      }
    }
  }
</script>
