<template>
  <Datatable v-bind:entries="users" :columns="columns" :title="'Users'"/>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { getCapability } from '../_helpers'
  import Datatable from '../components/Datatable.vue'
  export default {
    computed: {
      ...mapState('users', ['users', 'user']),
      columns(){
        return [
            {name: 'name', text: 'Name'},
            {name: 'email', text: 'Email'},
            {name: 'phone', text: 'Phone'},
            {name: 'account', text: 'Account',
              raw: {
                type: 'Sub',
                value: 'account_name'
              }
            },
            {name: 'status', text: 'Status',
              raw: {
                type: 'Text',
                fields: [
                  {value: '1', text: 'Active'},
                  {value: '2', text: 'Inactive'},
                ]
              }
            },
            {name: 'last_login', text: 'Last Login',
              raw: {
                type: 'Moment_UTC',
              }
            },
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('edit_user', this.user) ? {event: 'edit_user', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                  getCapability('edit_user', this.user) ? {event: 'edit_user_capability', text: 'Edit Role', method: 'get', class: 'btn btn-warning ml-1'} : {},
                  getCapability('delete_user', this.user) ? {event: 'users/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                ]
              }
            },
          ]
        }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('users', ['get_all']),
    },
    created() {
      this.$emit('onChildInit', 'Users', getCapability('add_user', this.user) ? '/users/add' : '')
      this.get_all()
    },
  }
</script>
