<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="parent_id">Account:</label>
        <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"  @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div v-if="telegram_setting !== null" class="form-group col-sm-12">
        <label for="type">Channel Telegram:</label>
        <input class="form-control" name="channel_name" type="text" v-model="telegram_setting.channel_name">
      </div>
      <div v-if="telegram_setting !== null"  class="form-group col-sm-6 required">
        <label for="status">Notification Custom Alert:</label>
        <select class="form-control" name="type" v-model.number="telegram_setting.custom">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div v-if="telegram_setting !== null"  class="form-group col-sm-6 required">
        <label for="status">Notification Overspeed:</label>
        <select class="form-control" name="type" v-model.number="telegram_setting.overspeed">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div v-if="telegram_setting !== null"  class="form-group col-sm-6 required">
        <label for="status">Notification Stop Duration:</label>
        <select class="form-control" name="type" v-model.number="telegram_setting.stop_duration">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div v-if="telegram_setting !== null"  class="form-group col-sm-6 required">
        <label for="status">Notification Offline Duration:</label>
        <select class="form-control" name="type" v-model.number="telegram_setting.offline_duration">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div v-if="telegram_setting !== null"  class="form-group col-sm-6 required">
        <label for="status">Notification Ignition Duration:</label>
        <select class="form-control" name="type" v-model.number="telegram_setting.ignition_duration">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div v-if="telegram_setting !== null"  class="form-group col-sm-6 required">
        <label for="status">Notification Idle Duration:</label>
        <select class="form-control" name="type" v-model.number="telegram_setting.idle_duration">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div v-if="telegram_setting !== null"  class="form-group col-sm-6 required">
        <label for="status">Notification Geofence In / Out:</label>
        <select class="form-control" name="type" v-model.number="telegram_setting.geofence_inout">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div v-if="telegram_setting !== null"  class="form-group col-sm-12">
        <input class="btn btn-warning" type="submit" value="Save" :disabled="this.onRequest">
      </div>
      <small>Step by Step to Use this Feature:<br/>
1. Create <b>new Channel Telegram</b> for each Account<br/>
2. Invite bot Tronfleet (@tronfleet_bot) to that Channel as <b>Admin</b><br/>
3. Invite other Telegram Member as desired<br/>
4. Fill in the Channel Telegram Name with (@) to the form<br/>
5. Activate any desired Telegram Notification<br/>
6. Please note that any Telegram Notification <b>only</b> works when Account created custom alert (including geofence alert)<br/>
</small>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('telegram_settings', ['telegram_setting', 'onRequest']),
    },
    data(){
      return {
        account_id: '',
        accountOptions: []
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest || this.telegram_setting === null) return;
        this.clear()
        this.edit(this.telegram_setting)
        this.account_id = ""
      },
      ...mapActions('telegram_settings', ['get', 'edit']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['clear']),
      onChange(event){
        if(event.id === "" || parseInt(event.id) < 1) return;
        this.get(parseInt(event.id))
      },
    },
    components:{
      Select2
    },
    created() {
      this.$emit('onChildInit', 'Telegram Settings')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
