<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>          
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="vehicles" :columns="columns" :title="'Vehicles'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  import { getCapability } from '../_helpers'
  export default {
    computed: {
      ...mapState('vehicles', ['vehicles', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'vehicle_no', text: 'Vehicle No'},
            {name: 'vehicle_code', text: 'Vehicle Code'},
            {name: 'stnk_number', text: 'STNK No'},
            {name: 'total_seat', text: 'Total Seat'},
            {name: 'machine_no', text: 'Machine No'},
            {name: 'chassis_no', text: 'Chassis No'},
            {name: 'capacity', text: 'Capacity (cc)'},
            {name: 'gps', text: 'GPS',
              raw: {
                type: 'Sub',
                value: 'imei'
              }
            },
            {name: 'gps', text: 'Sim Card',
              raw: {
                sub: 'sim_card',
                type: 'Sub_sub',
                value: 'gsm_no'
              }
            },
            {name: 'status', text: 'Status',
              raw: {
                type: 'Text',
                fields: [
                  {value: '1', text: 'Active'},
                  {value: '2', text: 'Inactive'},
                ]
              }
            },
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('edit_vehicle', this.user) ? {event: 'edit_vehicle', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                  getCapability('delete_vehicle', this.user) ? {event: 'vehicles/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                ]
              }
            },
          ]
        }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('vehicles', ['get_vehicle_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        this.get_vehicle_by_account({account_id: this.account_id})
      },
    },
    created() {
      this.$emit('onChildInit', 'Vehicles', getCapability('add_vehicle', this.user) ? '/vehicles/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
    }
  }
</script>
